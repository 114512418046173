<p-menubar *ngIf="!hidden && type != 'Side' && primeNgBugToggle" [model]="menus" [autoDisplay]="false" [class]="hostClasses"
           (dblclick)="switchNavigationType($event)">

  <!--  <ng-template pTemplate="end">-->
  <!--    <div class="flex align-items-center" *ngIf="enableSearch && isLoggedIn">-->
  <!--      <p-autoComplete #searchInputRef-->
  <!--                      [group]="autoCompleteGroupEnabled"-->
  <!--                      [lazy]="true"-->
  <!--                      [autoOptionFocus]="true"-->
  <!--                      [showClear]="true"-->
  <!--                      [showEmptyMessage]="false"-->
  <!--                      [forceSelection]="true"-->
  <!--                      [suggestions]="suggestions"-->
  <!--                      [completeOnFocus]="enableMostUsed"-->
  <!--                      (completeMethod)="search($event)"-->
  <!--                      (onBlur)="endSearch()"-->
  <!--                      aria-autocomplete="none"-->
  <!--                      autocomplete="off"-->
  <!--                      [placeholder]="'RES_DATALIST_Search' | translate"-->
  <!--                      field="text">-->
  <!--        <ng-template let-searchResult pTemplate="item">-->
  <!--          <div class="flex align-items-center gap-2" (mousedown)="mouseDownSuggestion($event, searchResult)">-->
  <!--            <div>-->
  <!--              <zappIcon class="index-search-icon" *ngIf="searchResult.isIndex && searchResult.icon" [themeIcon]="searchResult.icon"></zappIcon>-->
  <!--              <i *ngIf="searchResult.isIndex && !searchResult.icon" class="pi pi-search"></i>-->
  <!--              <i *ngIf="!searchResult.isIndex && searchResult.isFavorite()" class="pi pi-star-fill most-used-menu-icon"></i>-->
  <!--              <i *ngIf="!searchResult.isIndex && !searchResult.isFavorite()" class="pi pi-star most-used-menu-icon"></i>-->
  <!--              <b>{{ searchResult.text }}</b> &nbsp; <i>{{ searchResult.path }}</i>-->
  <!--              <i *ngIf="!searchResult.isIndex && searchResult.isMostUsed && !searchResult.isFavorite()" class="pi pi-trash most-used-menu-remove-icon"></i>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </ng-template>-->
  <!--      </p-autoComplete>-->
  <!--      <div class="menu-search-icon" (click)="openSearchModal()" title="Open search modal (Ctrl+Shift+P)">-->
  <!--        <i class="pi pi-search"></i>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </ng-template>-->

  <ng-template pTemplate="end">
    <div *ngIf="enableSearch && isLoggedIn" class="menu-search-icon" (click)="openSearchModal()" title="Open search modal (Ctrl+Shift+P)">
      <i class="pi pi-search"></i>
    </div>
  </ng-template>

</p-menubar>

<div *ngIf="!hidden && type == 'Side' && primeNgBugToggle"
     class="sidebar-container flex flex-column p-justify-between"
     (dblclick)="switchNavigationType($event)"
     [ngClass]="{'collapsed-menu': collapsedMenu && !searchActive}">

  <p-tieredMenu [model]="menusVertical[0]" [autoDisplay]="false" [class]="hostClasses" [styleClass]="'vertical'">
    <ng-template pTemplate="item" let-item let-hasSubmenu="hasSubmenu">
      <zappIcon *ngIf="!notCollapsible && collapsedMenu && !searchActive && menusVertical[0][0] === item" themeIcon="bars"
                [circularBackground]="false" (click)="toggleMenu()" class="toggle collapsed-toggle">
      </zappIcon>
      <div class="flex p-align-center">
        <a pRipple class="flex align-items-center p-menuitem-link flex-1" [routerLink]="item.routerLink">
          <span [class]="item.icon" *ngIf="item.icon" class="p-menuitem-icon"></span>
          <span *ngIf="!item.icon && collapsedMenu && !searchActive && menuItemIsRoot(item)" class="p-menuitem p-menuitem-icon"><i class="pi pi-circle-on"></i></span>
          <span class="p-menuitem-text" *ngIf="!collapsedMenu || searchActive || !menuItemIsRoot(item)">{{ item.label }}</span>
          <span *ngIf="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
            {{ item.shortcut }}
          </span>
          <i *ngIf="hasSubmenu && (!menuItemIsRoot(item) || !collapsedMenu || !hideRootMenuArrows)"
             class="pi pi-angle-right ml-auto"></i>
        </a>
        <zappIcon *ngIf="!notCollapsible && (!collapsedMenu || searchActive) && menusVertical[0][0] === item" themeIcon="bars"
                  [circularBackground]="false" (click)="toggleMenu()" class="toggle">
        </zappIcon>
      </div>
    </ng-template>
  </p-tieredMenu>

  <div>

    <p-tieredMenu [model]="menusVertical[1]" [class]="hostClasses" [styleClass]="'vertical bottom-menu'"
                  [ngClass]="{'collapsed-menu': collapsedMenu && !searchActive}">
      <ng-template pTemplate="item" let-item let-hasSubmenu="hasSubmenu">
        <div class="flex p-align-center">
          <a pRipple class="flex align-items-center p-menuitem-link flex-1" [routerLink]="item.routerLink">
            <span [class]="item.icon" *ngIf="item.icon" class="p-menuitem-icon"></span>
            <span *ngIf="!item.icon && collapsedMenu && !searchActive && menuItemIsRoot(item)" class="p-menuitem p-menuitem-icon"><i class="pi pi-circle-on"></i></span>
            <span class="p-menuitem-text" *ngIf="!collapsedMenu || searchActive || !menuItemIsRoot(item)">{{ item.label }}</span>
            <span *ngIf="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
              {{ item.shortcut }}
            </span>
            <i *ngIf="hasSubmenu && (!menuItemIsRoot(item) || !collapsedMenu || !hideRootMenuArrows)"
               class="pi pi-angle-right ml-auto"></i>
          </a>
        </div>
      </ng-template>
    </p-tieredMenu>

    <!--    <div *ngIf="enableSearch && isLoggedIn && !collapsedMenu || searchActive" class="p-mb-3">-->
    <!--      <p-autoComplete #searchInputRefSide-->
    <!--                      [group]="autoCompleteGroupEnabled"-->
    <!--                      [lazy]="true"-->
    <!--                      [autoOptionFocus]="true"-->
    <!--                      [showClear]="true"-->
    <!--                      [showEmptyMessage]="false"-->
    <!--                      [forceSelection]="true"-->
    <!--                      [suggestions]="suggestions"-->
    <!--                      [completeOnFocus]="enableMostUsed"-->
    <!--                      (completeMethod)="search($event)"-->
    <!--                      (onBlur)="endSearch()"-->
    <!--                      aria-autocomplete="none"-->
    <!--                      autocomplete="off"-->
    <!--                      [placeholder]="'RES_DATALIST_Search' | translate"-->
    <!--                      field="text">-->
    <!--        <ng-template let-searchResult pTemplate="item">-->
    <!--          <div class="flex align-items-center gap-2" (mousedown)="mouseDownSuggestion($event, searchResult)">-->
    <!--            <div>-->
    <!--              <zappIcon class="index-search-icon" *ngIf="searchResult.isIndex && searchResult.icon" [themeIcon]="searchResult.icon"></zappIcon>-->
    <!--              <i *ngIf="searchResult.isIndex && !searchResult.icon" class="pi pi-search"></i>-->
    <!--              <i *ngIf="!searchResult.isIndex && searchResult.isFavorite()" class="pi pi-star-fill most-used-menu-icon"></i>-->
    <!--              <i *ngIf="!searchResult.isIndex && !searchResult.isFavorite()" class="pi pi-star most-used-menu-icon"></i>-->
    <!--              <b>{{ searchResult.text }}</b> &nbsp; <i>{{ searchResult.path }}</i>-->
    <!--              <i *ngIf="!searchResult.isIndex && searchResult.isMostUsed && !searchResult.isFavorite()" class="pi pi-trash most-used-menu-remove-icon"></i>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </ng-template>-->
    <!--      </p-autoComplete>-->
    <!--      <div class="menu-search-icon" (click)="openSearchModal()" title="Open search modal (Ctrl+Shift+P)">-->
    <!--        <i class="pi pi-search"></i>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div *ngIf="enableSearch && isLoggedIn && collapsedMenu && !searchActive" class="search-icon-container p-mb-2">-->
    <!--      <zappIcon themeIcon="search"-->
    <!--                class="menu-search-icon"-->
    <!--                [circularBackground]="false"-->
    <!--                (click)="startSearch()">-->
    <!--      </zappIcon>-->
    <!--      <div class="menu-search-icon" (click)="openSearchModal()" title="Open search modal (Ctrl+Shift+P)">-->
    <!--        <i class="pi pi-search"></i>-->
    <!--      </div>-->
    <!--    </div>-->

    <div *ngIf="enableSearch && isLoggedIn" class="search-icon-container p-mb-2">
      <div class="menu-search-icon" (click)="openSearchModal()" title="Open search modal (Ctrl+Shift+P)">
        <i class="pi pi-search"></i>
      </div>
    </div>

  </div>

</div>

<zapp-searchmodal
  #SearchModal
  [indexes]="showIndexes"
  [showMenuSearch]="enableSearch"
  [showFavorites]="enableFavorites"
  [showMostUsed]="enableMostUsed"
  [menuItems]="items"
  [menuName]="name">
</zapp-searchmodal>
