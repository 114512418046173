import {
  Component,
  Input,
  HostBinding,
  ElementRef,
  ViewChild, Renderer2,
} from '@angular/core';
import {Subject} from 'rxjs';
import {filter, skip, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';
import {Location} from '@angular/common';

import {MenuInternalService} from './MenuControl.service';
import {zAppDevMenuItem} from './menu-item';

import * as ThemeOptions from '../../theme/interfaces/options';
import {IThemeSevice} from '../../theme/interfaces/theme.interface';
import {zAppDevComponentStatus} from '../component-status';
import {CF_COMPONENT} from '@framework/rule-engine/directives/condition-formatting.directive';
import {zAppDevBaseComponent} from '../BaseComponent/base.component';
import {ContextService} from '../../../@core/services/Context.service';
import {PermissionService} from '../../security/services/permission.service';
import {NavigationEnd, Router} from '@angular/router';
import {UserProfileSettingsService} from "@services/UserProfileSettings.service";
import {IndexSearchResult, IndexSearchService} from "@services/IndexSearch.service";
import {zAppSearchModalComponent} from "@framework/components/SearchModal/SearchModal.component";

@Component({
  selector: 'zapp-menu',
  styleUrls: ['./MenuControl.component.less'],
  templateUrl: './MenuControl.component.html',
  providers: [{provide: CF_COMPONENT, useExisting: zAppMenuControlComponent}]
})
export class zAppMenuControlComponent extends zAppDevBaseComponent {
  protected destroy$ = new Subject<void>();

  menus: MenuItem[];
  menusVertical: [MenuItem[], MenuItem[]];
  options: ThemeOptions.MenuThemeOptions;

  private _items: zAppDevMenuItem[];
  get items(): any {
    return this._items;
  }

  @Input() set items(value: any) {
    this._items = value;
    this.refreshMenu();
  }

  @Input() name: string = '';

  @Input() variation: string = 'Standard';

  @Input() status: zAppDevComponentStatus = 'default';

  @Input() class: string = '';

  @Input() type: string = "NavBar";
  @Input() allowUserToChangeLayout: boolean = true; // controls whether the user can change the
                                                    // orientation of the menu using double click
  @Input() hideRootMenuArrows: boolean = true; // whether to hide the angle right icons from
                                               // root menu items with children in collapsed mode

  @Input() notCollapsible: boolean = false;

  @Input() enableFavorites: boolean = true; // whether to show the favorites in the search results
  @Input() enableMostUsed: boolean = false; // whether to show the most used in the search results
  @Input() showIndexes: string[] = [];      // the indexes to show in the search results

  @Input() mostUsedCount: number = 4;
  @Input() favoritesCount: number = 4;

  protected collapsedMenu: boolean = false; // if the side menu is collapsed
  protected searchActive: boolean = false;  // if the search box is focused

  localeInitialized: boolean = false;

  primeNgBugToggle: boolean = true;

  @ViewChild('SearchModal') searchModal: zAppSearchModalComponent;

  // search
  @ViewChild('searchInputRef', { static: false, read: ElementRef }) searchInputRef;
  @ViewChild('searchInputRefSide', { static: false, read: ElementRef }) searchInputRefSide;
  @Input() enableSearch: boolean = false;
  isLoggedIn: boolean;
  suggestions: any[] | undefined;
  allSuggestions: any[] | undefined = [];

  autoCompleteGroupEnabled: boolean = false; // when this is true, enables the group feature of the autocomplete
                                             // this is set to true when the auto complete is open with the most used
                                             // or favorites and shows them in two groups

  private normalizeGreek(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  /**
   * Check if the given menu item is a root menu item. This is useful because root items
   * often have different behavior, for example in the presentation of the side version
   * of the menu.
   */
  protected menuItemIsRoot(item: MenuItem): boolean {
    return this.menusVertical[0].includes(item) || this.menusVertical[1].includes(item);
  }

  /**
   * Returns the menu items split into two arrays, the first one containing the items
   * that should be displayed on the left side of the menu, and the second one containing
   * the items that should be displayed on the right side of the menu. This method is
   * used on the side menu that determines the items to be displayed on the top and bottom.
   */
  splitMenu(menus: MenuItem[]): [MenuItem[], MenuItem[]] {
    const leftItems: MenuItem[] = [];
    const rightItems: MenuItem[] = [];

    menus.forEach(item => {
      if (item.styleClass?.indexOf('pull-right') > -1) {
        rightItems.push(item);
      } else {
        leftItems.push(item);
      }
    });

    return [leftItems, rightItems];
  }

  private prepareSeachIndex(menu: MenuItem, list: any[], path) {
    const item = {
      text: menu.label,
      path: path ?? "",
      isMostUsed: false,
      isFavorite: () => {
        return this.menuInternalService.getFavorites(this.name).has(menu.routerLink);
      },
      searchTerm: this.normalizeGreek(menu.label) + " " + this.normalizeGreek(path),
      routerLink: menu.routerLink
    };

    if (menu.routerLink != "" && menu.routerLink != null && menu.visible && !menu.disabled) {
      list.push(item);
    }

    if (menu.items != null) {
      for (let mi of menu.items) {
        this.prepareSeachIndex(mi, list,
          item.path + (item.path == "" ? "" : "/") + item.text
        );
      }
    }
  }

  openSearchModal() {
    this.searchModal.openModal();
  }

  getMenuNavigationType() {
    this.userProfileSettings.getProfileSetting(`MenuNavigationType_${this.name}`)
      .then((data) => {
        if (data == null || data == "" || (data != "Side" && data != "NavBar")) {
          this.type = "NavBar";
          this.setMenuNavigationType();
        } else {
          this.type = data as string;
        }
        this.processNavigationTypeChange();
      });
  }

  setMenuNavigationType() {
    this.userProfileSettings.setProfileSetting(`MenuNavigationType_${this.name}`, this.type);
  }

  switchNavigationType(event: MouseEvent) {
    // Check if user clicked on empty space
    let userClickedOnEmptySpace = false;
    const element = (event.target as HTMLElement);
    if (element.tagName === 'UL' && element.classList.contains('p-menubar-root-list')) {
      userClickedOnEmptySpace = true;
    }
    if (element.tagName === 'DIV' && element.classList.contains('sidebar-container')) {
      userClickedOnEmptySpace = true;
    }
    if (!userClickedOnEmptySpace) {
      return;
    }
    // Check if layout is allowed to change
    if (!this.allowUserToChangeLayout) {
      return;
    }
    // Perform action
    if (this.type === "Side") {
      this.type = "NavBar";
    } else {
      this.type = "Side";
    }
    this.setMenuNavigationType();
    this.processNavigationTypeChange();
  }

  processNavigationTypeChange() {
    if (this.type === "Side") {
      this.renderer.addClass(document.body, 'sidebar-menu');
    } else {
      this.renderer.removeClass(document.body, 'sidebar-menu');
    }
  }

  processCollapsedStateChange() {
    if (this.collapsedMenu) {
      this.renderer.addClass(document.body, 'sidebar-menu-collapsed');
    } else {
      this.renderer.removeClass(document.body, 'sidebar-menu-collapsed');
    }
  }

  convertSearchResultToSuggestion(searchResult: IndexSearchResult): any {
    return {
      text: searchResult.PrimaryLabel,
      path: searchResult.SecondaryLabel,
      isMostUsed: false,
      isFavorite: () => false,
      routerLink: searchResult.RouterLink,
      icon: searchResult.Icon,
      isIndex: true
    };
  }

  async search(ev) {
    // If the search query is empty, show the most used items
    if (!ev.query && this.enableMostUsed) {
      this.autoCompleteGroupEnabled = true;
      await this.populateMostUsedSuggestions();
      return;
    }
    // Otherwise, show the search results
    this.autoCompleteGroupEnabled = true;
    // Initialize suggestion groups
    const localSuggestions = [];
    // Add menu results
    const term = this.normalizeGreek(ev.query);
    const searchResults = this.allSuggestions.filter(a => a.searchTerm.indexOf(term) > -1);
    searchResults.forEach(s => s.isMostUsed = false);
    if (searchResults.length > 0) {
      localSuggestions.push({
        label: this.translate.instant("RES_MENU_SEARCH_SEARCH_RESULTS"),
        items: searchResults
      });
    }
    // Add index results
    const indexSearchResults = [];
    for (let index of this.showIndexes) {
      await this.indexSearchService.search(index, term, false, true)
        .then((indexResults) => {
          indexSearchResults.push(...indexResults);
        });
    }
    if (indexSearchResults.length > 0) {
      localSuggestions.push({
        label: this.translate.instant("RES_MENU_SEARCH_INDEX_RESULTS"),
        items: indexSearchResults.map((x) => this.convertSearchResultToSuggestion(x))
      });
    }
    // Set suggestions
    this.suggestions = localSuggestions;
  }

  async populateMostUsedSuggestions() {
    // Most used
    const mostUsedSuggestions = [];
    const mostUsedFromService = this.menuInternalService.getMostUsedMenuItems(this.name);
    Array.from(mostUsedFromService.entries())
      .sort((a, b) => b[1] - a[1])
      .forEach(([key, value]) => {
        const foundKey = this.allSuggestions.find(x => x.routerLink == key);
        if (!foundKey) {
          // If the item is not found, remove it from storage
          this.menuInternalService.removeMenuItemClickCount(key, this.name);
        } else {
          foundKey.isMostUsed = true;
          mostUsedSuggestions.push(foundKey);
        }
      });

    // Favorites
    const favoriteSuggestions = [];
    const favoritesFromService = this.menuInternalService.getFavorites(this.name);
    Array.from(favoritesFromService.keys())
      .forEach(key => {
        const foundKey = this.allSuggestions.find(x => x.routerLink == key);
        if (foundKey) {
          foundKey.isMostUsed = true;
          favoriteSuggestions.push(foundKey);
        }
      });

    // Build suggestions
    const localSuggestions = [];
    if (this.enableMostUsed) {
      localSuggestions.push({
        label: this.translate.instant("RES_MENU_SEARCH_MOST_USED"),
        items: mostUsedSuggestions.slice(0, this.mostUsedCount)
      });
    }
    if (this.enableFavorites) {
      localSuggestions.push({
        label: this.translate.instant("RES_MENU_SEARCH_FAVORITES"),
        items: favoriteSuggestions.slice(0, this.favoritesCount)
      });
    }
    this.suggestions = localSuggestions;
  }

  startSearch() {
    this.searchActive = true;
    setTimeout(() => this.searchInputRefSide.nativeElement.querySelector('input').focus(), 0);
  }

  endSearch() {
    this.searchActive = false;
  }

  increaseMostUsedOfRoute(route: string) {
    const foundItem = this.allSuggestions.find(x => x.routerLink == route);
    if (foundItem) {
      this.menuInternalService.incrementMenuItemClickCount(foundItem.routerLink, this.name);
    }
  }

  clearSearchTextBox() {
    setTimeout(() => {
      if (this.searchInputRef) {
        const input = this.searchInputRef.nativeElement.querySelectorAll("input")[0];
        if (input != null) {
          input.value = "";
        }
      }
      if (this.searchInputRefSide) {
        const inputSide = this.searchInputRefSide.nativeElement.querySelectorAll("input")[0];
        if (inputSide != null) {
          inputSide.value = "";
        }
      }
    }, 1);
  }

  mouseDownSuggestion(
    event: MouseEvent,
    searchResult: {routerLink: string, isMostUsed: boolean, isFavorite: () => boolean}
  ) {
    // Check if favorite mark was clicked
    if (event.target instanceof HTMLElement && event.target.classList.contains('most-used-menu-icon')) {
      if (searchResult.isFavorite()) {
        // Remove from favorites
        this.menuInternalService.removeFavorite(this.name, searchResult.routerLink);
        if (searchResult.isMostUsed) {
          this.populateMostUsedSuggestions();
        }
      } else {
        // Add to favorites
        this.menuInternalService.addFavorite(this.name, searchResult.routerLink);
        if (searchResult.isMostUsed) {
          this.populateMostUsedSuggestions();
        }
      }
      return;
    }
    // Check if trash mark was clicked
    if (event.target instanceof HTMLElement && event.target.classList.contains('most-used-menu-remove-icon')) {
      this.menuInternalService.removeMenuItemClickCount(searchResult.routerLink, this.name);
      this.populateMostUsedSuggestions();
      return;
    }
    // If middle click or control click
    if (event.button === 1 || event.ctrlKey) {
      const fullUrl = this.location.prepareExternalUrl(searchResult.routerLink);
      window.open(fullUrl, '_blank');
      this.clearSearchTextBox();
      return;
    }
    // If left click
    if (event.button === 0) {
      this.router.navigateByUrl(searchResult.routerLink);
      return;
    }
  }

  constructor(
    protected readonly translate: TranslateService,
    protected menuInternalService: MenuInternalService,
    private themeService: IThemeSevice,
    private location: Location,
    private router: Router,
    private userProfileSettings: UserProfileSettingsService,
    private indexSearchService: IndexSearchService,
    private renderer: Renderer2,
    private contextService: ContextService,
    private permissionService: PermissionService,
    protected elementRef: ElementRef) {
    super(elementRef);
    this.options = themeService.getMenuThemeOptions();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Initialize the navigation type
    if (this.allowUserToChangeLayout) {
      this.getMenuNavigationType();
      this.processNavigationTypeChange();
    }

    // Initialize the collapsed state
    this.userProfileSettings.getProfileSetting(`MenuCollapsed_${this.name}`)
      .then((data) => {
        this.collapsedMenu = (data == "true");
        this.processCollapsedStateChange();
      });

    // Initialize most used menu items
    this.menuInternalService.fetchMenu(this.name);

    this.translate
      .onLangChange
      .pipe()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.localeInitialized = true;
        this.refreshMenu();
      });

    this.contextService.context$
      .pipe(skip(1))
      .pipe(takeUntil(this.destroy$))
      .subscribe((context) => {
        this.isLoggedIn = context?.currentUser?.UserName != null && context?.currentUser?.UserName != "";

        if (!this.localeInitialized) {
          return;
        }

        this.refreshMenu();
      });

    this.permissionService.permissions
      .pipe(skip(1))
      .pipe(takeUntil(this.destroy$))
      .subscribe((context) => {

        if (!this.localeInitialized) {
          return;
        }

        this.refreshMenu();
      });

    // Subscribe to router events to update most used menu items on navigation end
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationEnd) => {
      // Increase the most used counter of navigated page
      this.increaseMostUsedOfRoute(event.url);
      // Clear search box
      this.clearSearchTextBox();
    });
  }

  refreshMenu() {
    const prevKey = this.getItemsKeys(this.menus);
    this.menus = this.menuInternalService.prepareItems(this.items, this.name);
    if (prevKey == this.getItemsKeys(this.menus)) {
      return;
    }

    this.menusVertical = this.splitMenu(this.menus);

    if (this.enableSearch == true) {
      this.allSuggestions = [];
      for (let mi of this.menus) {
        this.prepareSeachIndex(mi, this.allSuggestions, "");
      }
    }

    this.primeNgBugToggle = false;
    setTimeout(() => this.primeNgBugToggle = true, 0);
  }

  getItemsKeys(items: MenuItem[]) {
    if (items == null) {
      return "";
    }
    return items.length + "_" + items.map(a => a.label + "_" + a.visible + "_" + a.disabled + "_" + this.getItemsKeys(a.items)).join("|");
  }

  toggleMenu() {
    this.collapsedMenu = !this.collapsedMenu;
    this.userProfileSettings.setProfileSetting(`MenuCollapsed_${this.name}`, this.collapsedMenu ? "true" : "false");
    this.processCollapsedStateChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostBinding('style.height')
  get height() {
    return this.type == "Side" ? "100%" : "";
  }
}
